/* src/components/Footer.css */

.footer {
  background: var(--gradient-glass);
  backdrop-filter: blur(20px);
  border-radius: 100px;
  width: 100%;
  max-width: 1400px;
  border: 1px solid rgba(255,255,255,0.2);
  box-shadow: var(--shadow-3d);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: relative;
  margin: 20px auto;
  padding: 1.5rem 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 2.5rem;
}

.footer-logo {
  color: var(--secondary);
  font-weight: 700;
  font-size: 1.5rem;
  background: var(--gradient-primary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
}

.footer-copy {
  color: var(--text);
  font-size: 0.9rem;
  font-weight: 600;
}

.footer-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.footer-link {
  color: var(--text);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 100px;
  background: var(--gradient-shine);
  box-shadow: var(--shadow-neu);
  transition: all 0.3s var(--animation-bounce);
  font-weight: 600;
}

.footer-link:hover {
  background: var(--gradient-primary);
  color: white;
  box-shadow: var(--shadow-sm);
}

:root {
  --primary: #2563eb;
  --primary-dark: #1d4ed8;
  --secondary: #0f172a;
  --accent: #f97316;
  --background: #f8fafc;
  --surface: #ffffff;
  --text: #334155;
  --text-light: #64748b;
  --shadow-sm: rgba(0, 0, 0, 0.03) 0px 2px 4px;
  --shadow-md: rgba(0, 0, 0, 0.05) 0px 12px 24px -4px;
  --shadow-lg: rgba(0, 0, 0, 0.08) 0px 24px 48px -8px;
  --gradient-primary: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  --gradient-secondary: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  --gradient-accent: linear-gradient(135deg, #f59e0b 0%, #f97316 100%);
  --glass-background: rgba(255, 255, 255, 0.7);
  --glass-border: rgba(255, 255, 255, 0.5);
  --glass-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  --transition-bezier: cubic-bezier(0.4, 0, 0.2, 1);
  --gradient-glass: linear-gradient(120deg, rgba(255,255,255,0.3), rgba(255,255,255,0.1));
  --gradient-shine: linear-gradient(120deg, #ffffff20 0%, #ffffff10 100%);
  --shadow-3d: 0 10px 30px -10px rgba(0,0,0,0.1), 0 5px 10px -5px rgba(0,0,0,0.04);
  --shadow-inner: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  --shadow-neu: 12px 12px 24px #e2e8f0, -12px -12px 24px #ffffff;
  --animation-bounce: cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

/* src/pages/Home.css */

.home-container {
  padding: 3rem;
  max-width: 1400px;
  margin: 0 auto;
  perspective: 1000px;
}

.home-title {
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: -0.05em;
  text-align: center;
  margin-bottom: 3rem;
  background-image: linear-gradient(45deg, var(--primary) 15%, var(--accent) 50%, var(--primary) 85%);
  background-size: 200% auto;
  animation: shine 3s linear infinite;
  filter: drop-shadow(0 10px 10px rgba(0,0,0,0.1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  transform-style: preserve-3d;
  gap: 3rem;
  margin-bottom: 3rem;
}

.load-more-btn {
  background: var(--gradient-glass);
  backdrop-filter: blur(10px);
  color: white;
  padding: 1rem 2.5rem;
  border-radius: 100px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.4s var(--animation-bounce);
  box-shadow: var(--shadow-neu);
  transform: translateZ(20px);
}

.load-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.load-more-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .home-container {
    padding: 1rem;
  }
  
  .blogs-grid {
    grid-template-columns: 1fr;
  }
}

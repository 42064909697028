/* src/components/BlogCard.css */

.blog-card {
  display: flex;
  flex-direction: column;
  background: var(--gradient-glass);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 24px;
  overflow: hidden;
  box-shadow: var(--shadow-3d);
  margin: 20px 0;
  transition: all 0.4s var(--transition-bezier);
  animation: float 6s ease-in-out infinite;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.blog-card:hover {
  transform: translateY(-8px) scale(1.01);
  box-shadow: var(--shadow-lg);
}

.blog-thumbnail {
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  filter: brightness(1.05) contrast(1.05);
  transition: all 0.5s var(--transition-bezier);
  transform: translateZ(20px);
  box-shadow: var(--shadow-inner);
}

.blog-card:hover .blog-thumbnail {
  transform: scale(1.05);
}

.blog-content {
  padding: 2rem;
  position: relative;
}

.blog-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 1px;
  background: var(--gradient-primary);
  opacity: 0.2;
}

.blog-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--secondary);
  margin: 0 0 1rem 0;
  line-height: 1.4;
  background-size: 200% auto;
  background-image: linear-gradient(120deg, var(--secondary) 30%, var(--primary) 50%, var(--secondary) 70%);
  animation: shine 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
}

.blog-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-date {
  font-size: 0.9rem;
  color: #999;
}

.read-more {
  color: var(--primary);
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: gap 0.3s ease;
  position: relative;
  padding: 0.5rem 1rem;
  background: var(--gradient-shine);
  border-radius: 8px;
  box-shadow: var(--shadow-neu);
  transform: translateZ(10px);
}

.read-more:hover {
  gap: 0.75rem;
  text-decoration: underline;
}

.blog-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-excerpt {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  color: var(--text-light);
  gap: 1rem;
}

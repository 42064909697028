/* src/pages/BlogPost.css */

.blogpost-container {
  max-width: 768px;
  margin: 2rem auto 4rem;
  padding: 0 1.5rem;
  transition: all 0.3s ease;
}

.blogpost-thumbnail {
  width: 100%;
  height: 480px;
  object-fit: cover;
  border-radius: 24px;
  margin-bottom: 2.5rem;
  box-shadow: var(--shadow-lg);
  transition: transform 0.3s ease;
}

.blogpost-thumbnail:hover {
  transform: scale(1.02);
}

.blogpost-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--secondary);
  margin-bottom: 1rem;
}

.blogpost-meta {
  font-size: 1rem;
  color: var(--text-light);
  margin-bottom: 3rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.blogpost-content {
  font-size: 1.125rem;
  line-height: 1.8;
  color: var(--text);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.blogpost-content h2 {
  font-size: 2rem;
  color: var(--secondary);
  margin: 2.5rem 0 1.5rem;
}

.blogpost-content p {
  margin-bottom: 1.5rem;
}

.blogpost-content img {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  display: block;
  margin: 2rem auto;
}

.blogpost-content pre {
  background: var(--secondary);
  color: #fff;
  padding: 1.5rem;
  border-radius: 12px;
  margin: 2rem 0;
}

.blogpost-loading {
  text-align: center;
  padding: 40px;
  font-size: 1.2rem;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.blogpost-error {
  text-align: center;
  padding: 40px;
  color: #e74c3c;
  background: #fde8e7;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .blogpost-container {
    padding: 15px;
    margin: 20px auto;
  }

  .blogpost-thumbnail {
    height: 250px;
  }

  .blogpost-title {
    font-size: 2rem;
  }
}

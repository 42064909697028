/* src/App.css */

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f9f9f9;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.main-content {
  min-height: 100vh;
  transition: all 0.3s ease;
}

/* Add custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

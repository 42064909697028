/* src/components/Navbar.css */

body {
  padding-top: 120px; /* navbar height (70px) + top spacing (20px) + extra space (30px) */
}

.navbar {
  background: var(--gradient-glass);
  backdrop-filter: blur(20px);
  border-radius: 100px;
  width: 100%;
  max-width: 1400px;
  border: 1px solid rgba(255,255,255,0.2);
  box-shadow: var(--shadow-3d);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  max-width: 1200px;
  padding: 0 2.5rem;
}

.navbar-logo {
  color: var(--secondary);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  background: var(--gradient-primary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: var(--text);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  height: 100%;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s var(--animation-bounce);
  position: relative;
  border-radius: 100px;
  background: var(--gradient-shine);
  box-shadow: var(--shadow-neu);
  transform: translateZ(10px);
}

.nav-links:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background: var(--primary);
  transition: width 0.3s ease;
}

.nav-links:hover {
  background: var(--gradient-primary);
  color: white;
  box-shadow: var(--shadow-sm);
}

.nav-links:hover:after {
  width: 100%;
}
